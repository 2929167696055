import _path from "path";
import _pathKey from "path-key";
import _process from "process";
var exports = {};
var process = _process;
const path = _path;
const pathKey = _pathKey;

const npmRunPath = options => {
  options = {
    cwd: process.cwd(),
    path: process.env[pathKey()],
    execPath: process.execPath,
    ...options
  };
  let previous;
  let cwdPath = path.resolve(options.cwd);
  const result = [];

  while (previous !== cwdPath) {
    result.push(path.join(cwdPath, "node_modules/.bin"));
    previous = cwdPath;
    cwdPath = path.resolve(cwdPath, "..");
  } // Ensure the running `node` binary is used


  const execPathDir = path.resolve(options.cwd, options.execPath, "..");
  result.push(execPathDir);
  return result.concat(options.path).join(path.delimiter);
};

exports = npmRunPath; // TODO: Remove this for the next major release

exports.default = npmRunPath;

exports.env = options => {
  options = {
    env: process.env,
    ...options
  };
  const env = { ...options.env
  };
  const path = pathKey({
    env
  });
  options.path = env[path];
  env[path] = exports(options);
  return env;
};

export default exports;
export const env = exports.env;